import { createContext, useState } from 'react';
import { SignInIDPFn, SignInWithMicrosoft } from 'shared-components';
import { SignInWithGoogle } from 'shared-components/components/SignInWithGoogle';
import { signInIDP } from '../lib/signInIDP';
import { useRouter } from 'next/navigation';

export const SignInContext = createContext<SignInIDPFn | undefined>(undefined);

export const SignInProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();

  return (
    <SignInContext.Provider
      value={(data) => signInIDP(data).then(() => router.push('/'))}
    >
      {children}
    </SignInContext.Provider>
  );
};
