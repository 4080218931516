'use client';
import { SignInProvider } from '@/contexts/SignInContext';
import { loginUser } from '@/lib/loginUser';
import { signInIDP } from '@/lib/signInIDP';
import { useRouter } from 'next/navigation';
import React from 'react';
import { LoginUserForm, RegisterLink } from 'shared-components';
import { SignInWithGoogle } from './SignInWithGoogle';
import { SignInWithMicrosoft } from './SignInWithMicrosoft';

export const Login = () => {
  const router = useRouter();

  return (
    <div className='w-full'>
      <LoginUserForm
        signInIDP={(data) => signInIDP(data).then(() => router.push('/'))}
        loginUser={(data) => loginUser(data).then(() => router.push('/'))}
      />
      <SignInProvider>
        <div className='flex flex-col space-y-2'>
          <SignInWithGoogle />
          <SignInWithMicrosoft />
        </div>
      </SignInProvider>
      <div className='opacity-0'>
        <RegisterLink />
      </div>
    </div>
  );
};
